<template lang="pug">
nav.navbar.navbar-expand-md.navbar-dark.bg-dark.mb-4
  a.navbar-brand(href='/') {{appName}}
  button.navbar-toggler(type='button' data-toggle='collapse' data-target='#navbarCollapse' aria-controls='navbarCollapse' aria-expanded='false' aria-label='Toggle navigation')
    span.navbar-toggler-icon
  #navbarCollapse.collapse.navbar-collapse
    ul.navbar-nav.mr-auto
    //-   li.nav-item.active
    //-     a.nav-link(href='#')
    //-       | Home 
    //-       span.sr-only (current)
    //-   li.nav-item
    //-     a.nav-link(href='#') Link
    //-   li.nav-item
    //-     a.nav-link.disabled(href='#' tabindex='-1' aria-disabled='true') Disabled
    //- form.form-inline.mt-2.mt-md-0
    //-   input.form-control.mr-sm-2(type='text' placeholder='Search' aria-label='Search')
    //-   button.btn.btn-outline-success.my-2.my-sm-0(type='submit') Search

</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  }
}
</script>

<template lang="pug">
<Header/>
//- main.container(role='main')
  .jumbotron
    h1 {{appName}}
    p.lead La nueva iniciativa de la que muy pronto sabrás.
    p De momento, aquí tienes unos cuantos regalazos que puedes hacer para estas <a href="https://amzn.to/3fo76us" target="blank">navidades</a>. Aprovecha ahora que viene el <a href="https://amzn.to/3nIQIb1" target="_blank">black friday</a>.
    p Pronto te contaremos de qué va esto, pero te adelantamos que ya no tendrás que preocuparte de pensar qué regalar a tu pareja, familiar, amigo, amiga, compañera o comapñero de trabajo.
    a.btn.btn-lg.btn-primary(:href='newGiftUrl' role='button') Crear un nuevo regalo (próximamente) &raquo;

  
  .container
    .row
      .col.text-center(v-html="amazonProducts.topBanner")
    .row
      .col(v-for="product in amazonProducts.products")
        .text-center(v-html="product")



</template>

<script>
import Header from './components/Header'
import amazonProducts from './assets/amazon_products.json'

export default {
  name: 'Home',
  components: {
    Header
  },
  data () {
    return {
      newGiftUrl: process.env.VUE_APP_NEW_GIFT_URL,
      appName: process.env.VUE_APP_NAME,
      amazonProducts
    }
  }
}
</script>
